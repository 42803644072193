import React, { useState } from "react";
import Button from "../Button";

const InputForm = ({outputPoem, handlePoemBox, isOpen}) => {
  const [keyword, setKeyword] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    outputPoem(keyword)
    setKeyword('')
  }

  return ( 
    <form onSubmit={handleSubmit} className="inputform">
      <div className="flexbox inputform">
        <input 
          type="text"
          value={keyword} 
          className="textinput"
          onChange={(e) => setKeyword(e.target.value)} 
          placeholder={"talk to me"}
          accessKey=""
        />
        <input className="submit" type="submit" value="enter" />
        <Button handleOnClick={handlePoemBox}>{isOpen ? "↑" : "↓"}</Button>
      </div>
    </form>
   );
}
 
export default InputForm;