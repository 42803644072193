import React, { createContext, useContext, useState } from 'react'

const FilterContext = createContext()

export const FilterProvider = ({ children }) => {
  const [checkedGenres, setCheckedGenres] = useState(['fiction', 'documentary', 'animation', 'event', 'visual identity'])
  const [checkedFields, setCheckedFields] = useState(['cinema', 'fashion', 'music', 'spatialDesign', 'community'])

  return (
    <FilterContext.Provider value={{ 
        checkedGenres, 
        setCheckedGenres, 
        checkedFields,
        setCheckedFields
    }}>
      {children}
    </FilterContext.Provider>
  )
}

export const useFilter = () => useContext(FilterContext)
