import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { CameraControls } from '@react-three/drei'
import Plane from './Plane'
import Collaborator from './Collaborator'
import { graphql, useStaticQuery } from 'gatsby'
import Logo from './Logo'


export default function Experience({ isOval}) {

  const data = useStaticQuery(graphql`{
    allSanityProjects {
      nodes {
        _id
        title
        thumbnail {
          asset {
            url
          }
        }
        slug {
          current
        }
        genreTags
        industryTags
      }
    }
    allSanityFriends {
      nodes {
        _id
        artistName
        services
        slug {
          current
        }
      }
    }
  }`)
  const projects = data.allSanityProjects.nodes
  const collabs = data.allSanityFriends.nodes
  const cameraControlsRef = useRef()
  const logoRef = useRef()
  const pGroupRef = useRef()
  const cGroupRef = useRef()
  // const [firstRender, setFirstRender] = useState(true)

  
// position projects and collabs randomly
  const projPosRef = useRef([]) // takes objects of x,y,z
  const collabPosRef = useRef([]) // takes objects of x,y,z
  const collabRotRef = useRef([]) // takes objects of x,y,z
  const projRotRef = useRef([]) // takes objects of x,y,z

  const projFarLimit = 32
  const projNearLimit = 25
  const collabFarLimit = projNearLimit / 1.8
  const collabNearLimit = collabFarLimit / 2
  
  calcPosition(projRotRef, projPosRef, projNearLimit, projFarLimit, projects.length )
  calcPosition(collabRotRef, collabPosRef, collabNearLimit, collabFarLimit, (collabs.length + 1) )
  
  function calcPosition(rotRef, posRef, nearLimit, farLimit, arrLength) {
    if ( posRef.current.length !== arrLength ) { 
      for (let i = 0; i < arrLength; i++) {
        const radius = farLimit + Math.random() * (nearLimit - farLimit);
        const angle = Math.random() * Math.PI * 2;
        const x = Math.cos(angle) * radius;
        const z = Math.sin(angle) * radius;
        const distance = Math.sqrt(Math.pow(x, 2) + Math.pow(z, 2))
        const k = -0
        const y = k * distance
        const rotDist = (32 - distance + 7) * 0.0001

        const pos = {x: x,y: y,z: z}
        posRef.current.push(pos)
        rotRef.current.push(rotDist)
      }
    }
  }

// change view
  useEffect(()=>{
      const camC = cameraControlsRef.current

      // if(isOval) {
        camC.dolly(-100, true)
        camC.minDistance = 190
        camC.maxDistance= 190
        camC.rotate(Math.PI * 0.15, Math.PI * 0.1, true)
        // camC.fitToBox( logoRef.current.children[0], true )
      // } else {
      //   camC.reset(true)
      //   camC.minDistance = 90
      //   camC.maxDistance= 90
      //   camC.minPolarAngle = Math.PI * 0.5
      //   camC.maxPolarAngle = Math.PI * 0.5
      // }
      // return (() => {
      //   camC.minDistance = 1
      //   camC.maxDistance= Infinity
      //   camC.minPolarAngle = Math.PI * 0.35
      //   camC.maxPolarAngle = Math.PI * 0
      // })

  }, [/*isOval*/])

  return (
    <>
      <CameraControls 
        ref={cameraControlsRef}
        makeDefault
        enableZoom={false} 
        minPolarAngle={Math.PI * 0.35}
        maxPolarAngle={Math.PI * 0}
      />

      <group ref={cGroupRef}>
        {collabs && collabs.map((collab, index) =>
          <Collaborator 
            key={collab._id}
            collaborator={collab}
            position={collabPosRef.current[index]}
            rotation={collabRotRef.current[index]}
          />
        )}
      </group>
      <group ref={pGroupRef}>
      {projects && <>
        {projects.map((project, index) => {
          return <Plane 
            project={project}
            position={projPosRef.current[index]}
            key={project._id}
            rotation={projRotRef.current[index]}
          />
        })}
      </>}
      </group>

      <Logo 
        forwRef={logoRef}
        // position={collabPosRef.current[collabPosRef.current.length-1]} 
      />
      
    </>
  )
}
